<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t('Mesajlarım') }}</b-card-title>
    </b-card-header>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(catalog)="data">
        <div class="d-flex">
          <b-avatar
            :text="data.item.avatar"
            variant="primary"
            class="mr-1"
          />
          <b-link
            :to="$route.path + '/view/' + data.item.room_code"
            class="text-body"
          >
            {{ data.item.catalog }}
            <div class="font-small-2 text-info">
              {{ $t('Temsilci') }}: {{ data.item.username }}
            </div>
          </b-link>
        </div>
      </template>
      <template #cell(closed)="data">
        <div class="text-primary">
          <span v-if="data.item.closed">
            {{ $t('Görüşme Tamamlandı') }}
          </span>
          <span v-else>
            {{ $t('Görüşme Devam Ediyor') }}
          </span>
        </div>
      </template>
      <template #cell(control)="data">
        <b-button
          variant="primary"
          size="sm"
          :to="$route.path + '/view/' + data.item.room_code"
        >
          <FeatherIcon icon="FileTextIcon" />
          {{ $t('Görüntüle') }}
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BLink,
  BTable,
  BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BButton,
    BLink,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BAvatar,
  },
  data() {
    return {
      fields: [
        {
          key: 'catalog',
          label: this.$i18n.t('Katalog Adı'),
        },
        {
          key: 'closed',
          label: this.$i18n.t('Durum'),
          thClass: 'width-50 text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-50 text-nowrap',
          tdClass: 'text-nowrap text-right',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['chatMessages/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('chatMessages/getDataList')
    },
  },
}
</script>
